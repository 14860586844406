import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
import { graphql } from 'gatsby';
import { IoMdBed, GiCastle, MdBook, MdMap } from 'react-icons/all';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import FullImage from '@kastle/components/src/components/Image/FullImage';
import { CustomLayout, Head, ContentRow, ContentCol, Separator, SmallSpace } from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import LatestArticles from '../sections/LatestArticles';
import Presentation from '../sections/Presentation';
import { Routes } from '../routes';
export const query = graphql`
  query {
    bnb: file(
      relativePath: { eq: "2020/04/airbnb-chambres-dhotes-nancy.jpeg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    castle: file(relativePath: { eq: "chateau_de_morey.jpg" }) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hall: file(
      relativePath: { eq: "location_de_salles_entre_nancy_et_metz.jpg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hobby: file(
      relativePath: { eq: "place_stanislas_nancy_proche_chateau_de_morey.jpg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Presentation langKey={props.pageContext.langKey} mdxType="Presentation" />
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          <strong>Übernachtung und Frühstück</strong> zwischen <strong>Nancy</strong> und <strong>Metz</strong>
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
            Zwischen <strong>Nancy</strong> und <strong>Metz</strong>, am Rande des Waldes, für einen außergewöhnlichen Zwischenstopp. Verbringen Sie eine Nacht oder ein Wochenende in dieser Burg aus dem 16. Jahrhundert, die auf den Ruinen eines Templerklosters errichtet wurde. Ein einzigartiger, authentischer und geschichtsträchtiger Ort.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Die Familie Karst empfängt Sie in einem der 5 charmanten Gästezimmer. Schlendern Sie zum Klang der Vögel im Park mit jahrhundertealten Bäumen.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Die Familie Karst empfängt Sie in einem der 5 charmanten Gästezimmer. Schlendern Sie zum Klang der Vögel im Park mit jahrhundertealten Bäumen.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.de.BNB} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><IoMdBed mdxType="IoMdBed" /></SmallSpace>
            Räume
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.bnb?.childImageSharp?.gatsbyImageData} alt="Bed and Breakfast in der Nähe von Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Tertiary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">Le Château de Morey</Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          Im Herzen Lothringens, in einem renovierten Schloss aus dem 16.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Die ehemalige Residenz der Herzöge von Lothringen im 16. Jahrhundert wurde 1985 durch einen Brand vollständig zerstört. Es wurde mit der Familie KARST aus seiner Asche wiedergeboren.  Ruhe und Gelassenheit garantiert. Leicht zugänglich, 5 km von der A31 Ausfahrt 24 entfernt.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Die ehemalige Residenz der Herzöge von Lothringen im 16. Jahrhundert wurde 1985 durch einen Brand vollständig zerstört. Es wurde mit der Familie KARST aus seiner Asche wiedergeboren.  Ruhe und Gelassenheit garantiert. Leicht zugänglich, 5 km von der A31 Ausfahrt 24 entfernt.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.de.CASTLE} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><GiCastle mdxType="GiCastle" /></SmallSpace>
            Le Château
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.castle?.childImageSharp?.gatsbyImageData} alt="Authentisches Schloss in Lothringen bei Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
            Anmietung von Sälen in Lothringen zwischen Nancy und Metz
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
            Machen Sie Ihre Veranstaltung in einem authentischen Rahmen zu einem unvergesslichen Moment. Wir sind seit 1998 geöffnet und vermieten unsere Räume für Hochzeiten, Seminare, Geburtstage, Cocktails usw.. Vermietung von 3 Räumen, Küche für Caterer, Teil des Parks mit Schutz für Ehrenwein.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Wenn Sie einen Hochzeitsort in Lothringen in der Nähe von Nancy und Metz suchen. Wenn Sie einen idealen Rahmen suchen, um Ihre Veranstaltung zu einem unvergesslichen Moment zu machen! Kommen Sie und entdecken Sie das Château de Morey zwischen Nancy und Metz
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
             Es ist ein magischer Ort, für eine unvergessliche Hochzeit! Das Château de Morey ist ein außergewöhnlicher Ort, um den schönsten Tag Ihres Lebens zu organisieren.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Ein Schloss aus dem 16. Jahrhundert zur Dekoration mit einem Garten mit jahrhundertealten Bäumen. An einem wunderbar erhaltenen und gepflegten Ort kann das Schloss von Morey am Ende eines majestätischen Reitweges entdeckt werden.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.de.SPACE_RENTAL} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdBook mdxType="MdBook" /></SmallSpace>
            Mehr anzeigen
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hall?.childImageSharp?.gatsbyImageData} alt="Anmietung von Räumen in der Nähe von Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <LatestArticles mdxType="LatestArticles" />
    <Section type={SectionType.Primary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
            Freizeit im Schloss und Entdeckung Lothringens !
        </Heading>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Lothringen zu besuchen, zu reisen, bedeutet, sich von den anderen und anderswo überraschen und berühren zu lassen. Und es ist nicht immer notwendig, bis ans Ende der Welt zu reisen...
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.de.HOBBY} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdMap mdxType="MdMap" /></SmallSpace>
            Entdecken Sie
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hobby?.childImageSharp?.gatsbyImageData} alt="Die Place Stanislas und das UNESCO Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      